import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

import {
  HeaderWithAutoComplete,
  NavigationButtons,
  SnackBar,
  Maps,
} from "components";

import Delivery from "./Delivery";

import { images, texts } from "./constants";

import AddressSearchResults from "./AddressSearchResults";

import { Box, Typography } from "@mui/material";

import { useAddressList, useGeocoder, useProducts } from "hooks";
import useMap from "hooks/useMap";
import { useDispatch, useSelector } from "react-redux";
import { RootState, apiActions, geolocationActions } from "state";

import { useNavigate, useSearchParams } from "react-router-dom";

import AnimatedPage from "routing/AnimatedPage";
import { color_theme } from "../../color-theme";
import { isInServiceArea } from "../../utils/isInServiceArea";
import AddressFavorites from "./AddressFavorites";
import {
  authActions,
  ordersActions,
  shoppingCartActions,
  storeConfigActions,
} from "../../state/actions";
import ModalRequestInfo from "../../components/ModalRequestInfo";

const MapsLocal = () => {
  const { addresses, updateSearchQuery, lastAdresses } = useAddressList(false);
  const [disableButton, setDisableButton] = useState(true);
  const [currentPosition, setCurrentPosition] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const { geocode, getAddressFromLatLng } = useGeocoder();
  const addressesRecents = useSelector(
    (state: RootState) => state.addressesUser.lastAddresses
  );
  const addressesFavorites = useSelector(
    (state: RootState) => state.addressesUser.addresses
  );
  const { storeIsOpen } = useProducts();
  const navigate = useNavigate();
  const [requestInfo, setRequestInfo] = useState(false);

  const authToken = useSelector((state: RootState) => state.auth.token);
  const api = useSelector((state: RootState) => state.api.host);
  const originalApi = useRef({
    host: api,
    logged: Boolean(authToken),
  });
  const needOpen = useSelector(
    (state: RootState) =>
      state.auth.token &&
      state.user.id &&
      (!state.user.cpf ||
        !state.user.cpf_verified_at ||
        state.user.is_underage ||
        state.user.cpf_invalid)
  );

  const { goNext } = useMap();

  useEffect(() => {
    if (!addressesRecents?.length && authToken) {
      lastAdresses();
    }

    // eslint-disable-next-line
  }, []);

  const setSelected = async (address: any) => {
    const coordinates = await geocode(address.description);

    const { lat, lng } = coordinates;

    const addressFromLatLng = await getAddressFromLatLng(
      lat.toString(),
      lng.toString()
    );

    const payload = {
      ...address,
      coordinates,
      zipcode: addressFromLatLng.zipcode,
    };

    dispatch(apiActions.setApiFromState(addressFromLatLng.state));

    console.log("maps local @ set selected", { address });

    if (isInServiceArea(address.description)) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }

    dispatch(geolocationActions.setSelectedAddress(payload));
    setOpenModal(false);
  };

  const getCurrentPosition = () => {
    // @ts-ignore
    navigator.geolocation.getCurrentPosition(async (position: any) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      const address = await getAddressFromLatLng(
        latitude.toString(),
        longitude.toString()
      );

      dispatch(apiActions.setApiFromState(address.state));

      setCurrentPosition(address.address);
      setDisableButton(false);
      updateSearchQuery(address.address);

      dispatch(
        geolocationActions.setLocale({
          lat: latitude,
          lng: longitude,
          nameAddress: address.address,
          addressCityState: `${address.city} - ${address.state}`,
          zipcode: address.zipcode,
          street: address.street,
          number: address.number,
          district: address.district,
          city: address.city,
          state: address.state,
        })
      );

      console.log("maps local @ get current position", { address });

      const outOfServiceArea = !isInServiceArea(address.city, { strict: true });

      setDisableButton(outOfServiceArea);
    });
    setDisableButton(false);
    setOpenModal(false);
  };

  const [searchParams] = useSearchParams();
  const setAppVersionCode = () => {
    const appVersionCode = searchParams.get("appVersionCode");
    dispatch(apiActions.setAppVersionCode(appVersionCode));
  };

  useEffect(() => {
    setAppVersionCode();
    getCurrentPosition();

    // eslint-disable-next-line
  }, []);

  const goToAddressModal = () => {
    setOpenModal(true);
  };

  const handleNext = async () => {
    const res = await storeIsOpen();
    dispatch(storeConfigActions.setStoreConfig(res));

    if (originalApi.current.host !== api) {
      dispatch(authActions.logout());
      dispatch(ordersActions.resetOrders());
      dispatch(shoppingCartActions.cleanShoppingCart());
      originalApi.current.logged = false;
    }

    if (needOpen) {
      setRequestInfo(true);
      return;
    }

    setOpenModal(false);
    if (res.auto_register || originalApi.current.logged) {
      goNext();
    } else {
      navigate("/login", { state: { nextRoute: "/catalog" } });
    }
  };

  return (
    <>
      <HeaderWithAutoComplete setOpenModal={setOpenModal} />
      <SnackBar
        text={texts.selectAddress}
        removeArrows={true}
        event={goToAddressModal}
      />
      <ModalRequestInfo open={requestInfo} openModal={setRequestInfo} />
      {openModal && (
        <>
          <Box
            sx={{
              flex: 1,
              height: "calc(100vh - 120px)",
              width: "100%",
              top: "119px",
              position: "absolute",
              zIndex: 1,
              backgroundColor: color_theme.background.white.main,
            }}
          >
            <AddressFavorites
              addressesFavorites={addressesFavorites}
              handleClick={(addressFav: any) => setSelected(addressFav)}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderBottom: "1px solid black",
                p: "16px 32px 16px 32px",
                gap: 2,
              }}
              onClick={getCurrentPosition}
            >
              <img
                style={{ height: "29px", width: "21px" }}
                src={images.marker}
                alt="Selecionar localização atual"
              />
              <div>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "20px",
                    mb: 1,
                  }}
                >
                  usar localização atual
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "12px",
                    lineHeight: "15px",
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    color: color_theme.text.black.main,
                  }}
                >
                  {currentPosition}
                </Typography>
              </div>
            </Box>
            <Box pb={7}>
              {addresses.length ? (
                <>
                  {addresses.map(
                    (address: google.maps.places.AutocompletePrediction) => (
                      <div key={address.place_id.toString()}>
                        <AddressSearchResults
                          address={address}
                          handleClick={() => setSelected(address)}
                        />
                      </div>
                    )
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    pt: 5,
                    pb: "39px",
                    pl: 4,
                    pr: "37px",
                  }}
                >
                  Pesquise o endereço desejado
                </Box>
              )}
            </Box>
            <Box
              sx={{
                zIndex: 1,
                position: "fixed",
                width: "100%",
                bottom: 0,
              }}
            >
              <NavigationButtons
                rightTitle="voltar"
                rightAction={() => setOpenModal(false)}
                removeArrows
              />
            </Box>
          </Box>
        </>
      )}
      <AnimatedPage>
        <Maps />
      </AnimatedPage>
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: 55 }}
        exit={{ height: 0 }}
        transition={{ duration: 0.3 }}
        style={{
          bottom: "80px",
          width: "100%",
        }}
      >
        <Delivery outOfServiceArea={disableButton} />
      </motion.div>
      <NavigationButtons
        // leftTitle={texts.back}
        // leftAction={() => navigateTo("/")}
        rightTitle={texts.next}
        rightAction={handleNext}
        rightDisabled={disableButton}
      />
    </>
  );
};

export default MapsLocal;
