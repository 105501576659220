import { useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMapEvents,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import { useDispatch, useSelector } from "react-redux";

import { useAddressList, useGeocoder } from "hooks";
import { apiActions, geolocationActions, RootState } from "state";

import { images } from "./constants";

import "leaflet/dist/leaflet.css";

const markerIcon = new L.Icon({
  iconUrl: images.marker,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const getZoom = (currentZoom: number) => {
  const defaultZoom = 17;

  if (currentZoom > defaultZoom) return currentZoom;

  return defaultZoom;
};

const MapController = ({ lat, lng }: { lat: number; lng: number }) => {
  const map = useMap();

  useEffect(() => {
    if (lat && lng) {
      map.flyTo([lat, lng], getZoom(map.getZoom()), { animate: true });
    }
  }, [lat, lng, map]);

  return null;
};

const LocationMarker = ({ lat, lng }: { lat: number; lng: number }) => {
  const position = new L.LatLng(lat, lng);

  const { getAddressFromLatLng } = useGeocoder();
  const dispatch = useDispatch();
  const { updateSearchQuery } = useAddressList();

  const map = useMapEvents({
    click: async (e) => {
      const { lat, lng } = e.latlng;
      const address = await getAddressFromLatLng(String(lat), String(lng));

      dispatch(
        geolocationActions.setLocale({
          lat: lat,
          lng: lng,
          nameAddress: address.address,
          addressCityState: `${address.city} - ${address.state}`,
          zipcode: address.zipcode,
          street: address.street,
          number: address.number,
          district: address.district,
          city: address.city,
          state: address.state,
        })
      );
      dispatch(apiActions.setApiFromState(address.state));
      updateSearchQuery(address.address);

      map.flyTo(e.latlng, getZoom(map.getZoom()), { animate: true });
    },
  });

  return position ? <Marker position={position} icon={markerIcon} /> : null;
};

export const Maps = () => {
  const latLng = useSelector(
    (state: RootState) => state.geolocation.selectedAddress.coordinates
  );

  return (
    <MapContainer
      center={[latLng.lat, latLng.lng]}
      zoom={13}
      style={{ width: "100%", height: "100%", zIndex: 0 }}
      placeholder={
        <div style={{ textAlign: "center", padding: "20px" }}>
          Carregando mapa...
        </div>
      }
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      <MapController lat={latLng.lat} lng={latLng.lng} />

      <LocationMarker lat={latLng.lat} lng={latLng.lng} />
    </MapContainer>
  );
};
